@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#menu-appbar ul {
  width: 224px;
  padding-top: 0;
  padding-bottom: 0;
}

#menu-appbar .MuiPaper-root {
  top: 54px !important;
}
